import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import { Target } from "../../utils"
import Info from "../shared/about/Info"
import HeadingM from "../shared/HeadingM"

const query = graphql`
  query {
    room: file(relativePath: { eq: "nico/building.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 500)
      }
    }
  }
`

const AboutInfo = () => {
  const data = useStaticQuery(query)
  const items = {
    name: "nico",
    ceo: "中村　佑也",
    openDate: "2020年4月",
    address: "〒378-0053\n群馬県沼田市東原新町１８３８−３",
    openingHours: "​9:00 - 18:00",
    phone: "0278-25-4970",
    target: "nico" as Target,
    image: getImage(data.room),
    mapLink: "https://goo.gl/maps/uTEpdapLMPMVEXDr8",
  }
  return (
    <div>
      <HeadingM title="施設について" target="nico" />
      <Info {...items} />
    </div>
  )
}

export default AboutInfo
